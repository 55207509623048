import { Modal } from '@depop/web-ui-kit/Modal';
import { H2 } from '@depop/web-ui-kit/Typography/H2';
import { useTranslations } from 'next-intl';
import React, { useState } from 'react';

import { CounterOfferModalForm } from './CounterOfferModalForm';
import { CounterOfferModalSuccess } from './CounterOfferModalSuccess';
import styles from './styles.module.css';

import { CounterOfferProductPrice, OfferRole } from '@/modules/offers/types';
import { useOffersTracking } from '@/modules/offers/hooks/useOffersTracking';

export type Props = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  currencySymbol: string;
  productPrice: CounterOfferProductPrice;
  userRole: OfferRole;
  productId: number;
  offerId: string;
  offererUsername: string;
  imageHref?: string;
  variantId?: number;
  onOfferSent: () => void;
};

export function CounterOfferModal({
  isOpen,
  setOpen,
  currencySymbol,
  productPrice,
  userRole,
  imageHref,
  productId,
  offerId,
  variantId,
  offererUsername,
  onOfferSent,
}: Props) {
  const [hasCounterOfferSentSuccessfully, setHasCounterOfferSentSuccessfully] =
    useState(false);
  const t = useTranslations('offers');
  const { sendOffersSentViewEvent } = useOffersTracking();

  function onSuccessfulCounterOffer() {
    onOfferSent();
    setHasCounterOfferSentSuccessfully(true);
    sendOffersSentViewEvent(productId);
  }

  return (
    <Modal
      isOpen={isOpen}
      setOpen={setOpen}
      customOverrides={{
        header: (
          <div className={styles.counterOfferModalHeadingContainer}>
            <H2>
              {hasCounterOfferSentSuccessfully
                ? t('CounterOfferModal.OfferSent')
                : t('CounterOfferModal.Title')}
            </H2>
          </div>
        ),
      }}
      content={() => (
        <div className={styles.counterOfferModalContainer}>
          {hasCounterOfferSentSuccessfully ? (
            <CounterOfferModalSuccess
              username={offererUsername}
              onExitClick={() => setOpen(false)}
            />
          ) : (
            <CounterOfferModalForm
              currencySymbol={currencySymbol}
              productPrice={productPrice}
              imageHref={imageHref}
              userRole={userRole}
              productId={productId}
              offerId={offerId}
              variantId={variantId}
              onSuccessfulCounterOffer={onSuccessfulCounterOffer}
              isOpen={isOpen}
            />
          )}
        </div>
      )}
    />
  );
}
