import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { CurrencyCode } from '@/modules/currencies/constants';
import {
  CounterOfferProductPrice,
  OfferProductPrice,
  OfferRole,
} from '@/modules/offers/types';

export function useOffersTracking() {
  const [, currentUser] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });

  function sendOffersPageViewEvent() {
    sendActivityTrackerEvent(ActivityTrackerEventType.OFFER_CHAT_VIEW, {
      schemaVersion: '4.0',
    });
  }

  function sendOffersSentViewEvent(productId: number) {
    sendActivityTrackerEvent(ActivityTrackerEventType.OFFER_SENT_VIEW, {
      productId,
    });
  }

  function sendMakeOfferViewEvent(productId: number) {
    sendActivityTrackerEvent(ActivityTrackerEventType.MAKE_OFFER_VIEW, {
      productId,
    });
  }

  function sendSuggestedOffersActionEvent(
    productId: number,
    offerPrice: string,
    currency: CurrencyCode
  ) {
    sendActivityTrackerEvent(ActivityTrackerEventType.SUGGESTED_OFFERS_ACTION, {
      productId,
      offerPrice,
      currency,
      role: 'buyer',
      schemaVersion: '2.0',
    });
  }

  function sendMakeOfferFormSubmitEvents(
    productId: number,
    offerPrice: string,
    productPrice: OfferProductPrice
  ) {
    sendActivityTrackerEvent(ActivityTrackerEventType.SEND_OFFER_ACTION, {
      productId,
      offerPrice,
      currency: productPrice.currencyName,
      originalPrice: productPrice.baseOriginalPrice,
      currentPrice: productPrice.baseCurrentPrice,
      user: 'Buyer',
      type: 'FirstOffer',
      schemaVersion: '3.0',
    });

    sendActivityTrackerEvent(ActivityTrackerEventType.MAKE_OFFER_ACTION, {
      productId,
      user: 'Buyer',
      type: 'FirstOffer',
      section: 'ProductPage',
      schemaVersion: '5.0',
    });
  }

  function sendCounterOfferFormSubmitEvent({
    productId,
    offerId,
    offerPrice,
    productPrice,
    role,
  }: {
    productId: number;
    offerId: string;
    offerPrice: string;
    productPrice: CounterOfferProductPrice;
    role: OfferRole;
  }) {
    sendActivityTrackerEvent(ActivityTrackerEventType.SEND_OFFER_ACTION, {
      productId,
      offerId,
      offerPrice,
      currency: productPrice.currencyName,
      originalPrice: productPrice.baseOriginalPrice,
      currentPrice: productPrice.baseCurrentPrice,
      user: role,
      type: 'CounterOffer',
      schemaVersion: '3.0',
    });
  }

  function sendOfferBuyNowEvent({
    productId,
    currency,
    originalPrice,
    currentPrice,
    offerPrice,
  }: {
    productId: number;
    currency: CurrencyCode;
    originalPrice: string;
    currentPrice: string;
    offerPrice: string;
  }) {
    sendActivityTrackerEvent(ActivityTrackerEventType.OFFER_BUY_NOW, {
      productId,
      currency,
      originalPrice,
      currentPrice,
      offerPrice,
    });
  }

  return {
    sendOffersPageViewEvent,
    sendMakeOfferViewEvent,
    sendOffersSentViewEvent,
    sendSuggestedOffersActionEvent,
    sendMakeOfferFormSubmitEvents,
    sendOfferBuyNowEvent,
    sendCounterOfferFormSubmitEvent,
  };
}
