import { useMutation } from '@tanstack/react-query';

import { createCounterOfferPriceQuote } from '../api';
import {
  OfferError,
  OfferPriceQuoteRequestBody,
  OfferPriceQuoteResponse,
} from '../types';

import { AxiosCompatibleError } from '@/modules/http/types';

type Props = {
  offerId: string;
  onSuccess: (data: OfferPriceQuoteResponse) => void;
  onError?: (error: OfferError) => void;
};

export function useCounterOfferPriceQuoteMutation({
  offerId,
  onSuccess,
  onError,
}: Props) {
  return useMutation({
    mutationFn: (body: OfferPriceQuoteRequestBody) =>
      createCounterOfferPriceQuote(offerId, body),
    onSuccess: (data) => {
      onSuccess(data.data);
    },
    onError: async (error: AxiosCompatibleError<OfferError>) => {
      const errorData = error?.response?.data;
      if (onError) {
        onError(errorData);
      }
    },
  });
}
