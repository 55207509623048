import { buyerStatuses, sellerStatuses } from '../constants';
import { BuyerOfferDisplayStatus } from '../types';

export function getOfferPriceTextKey(
  offerDisplayStatus: BuyerOfferDisplayStatus
) {
  if (buyerStatuses.has(offerDisplayStatus)) {
    return 'SellerSendOffer.YourOffer';
  }

  if (sellerStatuses.has(offerDisplayStatus)) {
    return 'CounterOfferModal.SellersOffer';
  }

  return '';
}
