import React from 'react';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

import { OfferRole } from '@/modules/offers/types';

type OfferPriceTextProps = {
  currencySymbol: string;
  offerValue?: string;
  text: string;
};

function OfferPriceText({
  currencySymbol,
  offerValue,
  text,
}: OfferPriceTextProps) {
  return (
    <span className={styles.offerHistoryValues}>
      <Text bold>{`${currencySymbol}${offerValue}`}</Text>
      <Text className={styles.offerHistoryValuesCaption} type="caption1">
        {text}
      </Text>
    </span>
  );
}

type OfferHistoryProps = {
  userRole: OfferRole;
  currencySymbol: string;
  currentPrice: string;
  offerersHighestOffer?: string;
  sellersLowestOffer?: string;
};

export function OfferHistory({
  userRole,
  currencySymbol,
  currentPrice,
  offerersHighestOffer,
  sellersLowestOffer,
}: OfferHistoryProps) {
  const t = useTranslations('offers');

  return (
    <div className={styles.offerHistoryContainer}>
      <div className={styles.offerHistoryProgressBarContainer}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height={48}
          viewBox="0 0 15 48"
          fill="none"
        >
          <rect
            x="14.75"
            width={48}
            height="14"
            rx="7"
            transform="rotate(90 14.75 0)"
            fill="#F3F3F3"
          />
          <circle
            cx="7.75"
            cy="7"
            r="5"
            transform="rotate(90 7.75 7)"
            fill="#747474"
          />
          <circle
            cx="7.75"
            cy={41}
            r="5"
            transform="rotate(90 7.75 41)"
            fill="#3D37BD"
            stroke="#C5E0FF"
          />
        </svg>
      </div>
      <div className={styles.offerHistoryValuesContainer}>
        {userRole === OfferRole.Buyer ? (
          <>
            {offerersHighestOffer && (
              <OfferPriceText
                currencySymbol={currencySymbol}
                offerValue={offerersHighestOffer}
                text={t('CounterOfferModal.YouOffered')}
              />
            )}
            <OfferPriceText
              currencySymbol={currencySymbol}
              offerValue={sellersLowestOffer}
              text={t('CounterOfferModal.SellersOffer')}
            />
          </>
        ) : (
          <>
            {sellersLowestOffer ? (
              <OfferPriceText
                currencySymbol={currencySymbol}
                offerValue={sellersLowestOffer}
                text={t('CounterOfferModal.YouCountered')}
              />
            ) : (
              <OfferPriceText
                currencySymbol={currencySymbol}
                offerValue={currentPrice}
                text={t('CounterOfferModal.CurrentPrice')}
              />
            )}
            <OfferPriceText
              currencySymbol={currencySymbol}
              offerValue={offerersHighestOffer}
              text={t('CounterOfferModal.BuyersOffer')}
            />
          </>
        )}
      </div>
    </div>
  );
}
