import {
  BUYER_ACTIVE_OFFERS_ENDPOINT,
  BUYER_INACTIVE_OFFERS_ENDPOINT,
  COUNTER_OFFERS_PRICE_QUOTE_ENDPOINT,
  COUNTER_OFFER_ENDPOINT,
  DISMISS_OFFER_ENDPOINT,
  OFFERS_ENDPOINT,
  OFFERS_PRICE_QUOTE_ENDPOINT,
} from '@/constants/endpoints';
import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types';
import {
  ActiveOffersParams,
  CounterOfferRequestBody,
  CreateOfferRequestBody,
  OfferPriceQuoteRequestBody,
  OfferPriceQuoteResponse,
  OfferResponse,
} from '@/modules/offers/types';

export function createOffer(body: CreateOfferRequestBody) {
  return http.post(OFFERS_ENDPOINT, body, { withAuth: true });
}

export function createCounterOffer(
  offerId: string,
  body: CounterOfferRequestBody
) {
  return http.post(COUNTER_OFFER_ENDPOINT.replace(':offer_id', offerId), body, {
    withAuth: true,
  });
}

export async function createOfferPriceQuote(
  body: OfferPriceQuoteRequestBody
): Promise<AxiosCompatibleResponse<OfferPriceQuoteResponse>> {
  return http.post(OFFERS_PRICE_QUOTE_ENDPOINT, body, {
    withAuth: true,
  });
}

export async function createCounterOfferPriceQuote(
  offerId: string,
  body: OfferPriceQuoteRequestBody
): Promise<AxiosCompatibleResponse<OfferPriceQuoteResponse>> {
  return http.post(
    COUNTER_OFFERS_PRICE_QUOTE_ENDPOINT.replace(':offerId', offerId),
    body,
    {
      withAuth: true,
    }
  );
}

export function fetchBuyerActiveOffers(
  params: ActiveOffersParams
): Promise<AxiosCompatibleResponse<OfferResponse>> {
  return http.get(BUYER_ACTIVE_OFFERS_ENDPOINT, {
    params,
    withAuth: true,
  });
}

export function fetchBuyerInactiveOffers(
  params: ActiveOffersParams
): Promise<AxiosCompatibleResponse<OfferResponse>> {
  return http.get(BUYER_INACTIVE_OFFERS_ENDPOINT, {
    params,
    withAuth: true,
  });
}

export function dismissOffer(offerId: string) {
  return http.post(
    DISMISS_OFFER_ENDPOINT.replace(':offerId', offerId),
    {},
    { withAuth: true }
  );
}
