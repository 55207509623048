import { Decimal } from 'decimal.js';

export function getOfferPriceSuggestions(offerPrice: number) {
  return [
    {
      price: new Decimal(offerPrice).times(0.8).toFixed(2),
      percentageDiscount: 20,
    },
    {
      price: new Decimal(offerPrice).times(0.85).toFixed(2),
      percentageDiscount: 15,
    },
    {
      price: new Decimal(offerPrice).times(0.9).toFixed(2),
      percentageDiscount: 10,
    },
  ];
}
