import { useMutation } from '@tanstack/react-query';

import { dismissOffer } from '../api';

type Props = {
  offer_id: string;
  onSuccess: () => void;
};

export function useDismissOfferMutation({ offer_id, onSuccess }: Props) {
  return useMutation({
    mutationFn: () => dismissOffer(offer_id),
    onSuccess,
  });
}
