import { CurrencyCode } from '@/modules/currencies/constants';
import { ProductPrice, ThumbnailFormat } from '@/modules/product/types';

export type OfferProductPrice = Omit<
  ProductPrice,
  'currentSalesTax' | 'isReduced' | 'totalOriginalPrice'
> & {
  nationalShippingCost?: string;
};

export type CounterOfferProductPrice = {
  sellersLowestOffer?: string;
  offerersHighestOffer?: string;
} & OfferProductPrice;

export type OfferError = {
  code: number;
  message: string;
  action: string;
  id: string;
};

export type CreateOfferRequestBody = {
  product_id: number;
  offer_value: string;
  offer_currency: string;
  variant_id?: number;
};

export type CounterOfferRequestBody = {
  product_id: number;
  offer_value: string;
  offer_currency: string;
  variant_id?: number;
};

export type OfferPriceQuoteRequestBody = {
  product_id: number;
  offer_value: string;
  offer_currency: CurrencyCode;
  variant_id?: number;
};

export type OfferPriceQuoteResponse = {
  price_amount?: string;
  price: string;
  total_estimated_price_amount: string;
  price_break_down: {
    item_price: string;
    tax?: string;
    marketplace_fee?: string;
  };
};

export type ActiveOffersParams = {
  offset_id?: string;
  limit?: number;
  force_fee_calculation?: boolean;
};

export enum BuyerOfferDisplayStatus {
  ACCEPTED = 'ACCEPTED',
  BINDING_COUNTER_SENT = 'BINDING_COUNTER_SENT',
  BINDING_SENT = 'BINDING_SENT',
  COUNTER_RECEIVED = 'COUNTER_RECEIVED',
  COUNTER_SENT = 'COUNTER_SENT',
  DISMISSED = 'DISMISSED',
  EXPIRED_PRIVATE_OFFER = 'EXPIRED_PRIVATE_OFFER',
  EXPIRED_RECEIVED = 'EXPIRED_RECEIVED',
  EXPIRED_SENT = 'EXPIRED_SENT',
  RECEIVED = 'RECEIVED',
  RECEIVED_PRIVATE_OFFER = 'RECEIVED_PRIVATE_OFFER',
  REDEEMED = 'REDEEMED',
  REJECTED = 'REJECTED',
  SENT = 'SENT',
  SOLD_PRIVATE_OFFER = 'SOLD_PRIVATE_OFFER',
  SOLD_RECEIVED = 'SOLD_RECEIVED',
  SOLD_SENT = 'SOLD_SENT',
}

export type Offer = {
  offer_id: string;
  product_id: number;
  product_slug: string;
  product_description: string;
  product_country_code: string;
  picture_data: {
    id: number;
    formats: Record<string, ThumbnailFormat>;
  };
  seller_id: number;
  seller_username: string;
  seller_picture_data?: {
    id: number;
    formats: Record<string, ThumbnailFormat>;
  };
  seller_first_name: string;
  seller_last_name: string;
  seller_badge?: string;
  offer_value: string;
  prices: {
    original_price: {
      price: string;
      total_estimated_price_amount: string;
      price_break_down: {
        item_price: string;
        marketplace_fee: string;
      };
      price_amount: string;
    };
    current_price: {
      price: string;
      total_estimated_price_amount: string;
      price_break_down: {
        item_price: string;
        marketplace_fee: string;
      };
      price_amount: string;
    };
  };
  price_currency: CurrencyCode;
  offer_display_status: BuyerOfferDisplayStatus;
  expires_at: string;
  variant_set: number;
  variant_id: number;
  can_make_new_offer: boolean;
  can_make_counter_offer: boolean;
  national_shipping_cost_for_buyer: string;
  international_shipping_cost_for_buyer?: string;
  offerers_highest_offer_value: string;
  sellers_lowest_offer_value?: string;
  offer_price: OfferPriceQuoteResponse;
};

export type OfferResponse = {
  objects: Offer[];
  meta: {
    end: boolean;
    last_offset_id?: string;
  };
};

export enum OfferRole {
  Buyer = 'buyer',
  Seller = 'seller',
}
