import { useInfiniteQuery } from '@tanstack/react-query';

import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { activeOffersInfiniteQueryOptions } from '@/modules/offers/queries';
import { ActiveOffersParams } from '@/modules/offers/types';

type Props = {
  enabled?: boolean;
  params?: Omit<ActiveOffersParams, 'offset_id'>;
};

const defaultProps: Props = {
  enabled: true,
  params: {},
};

export function useBuyersActiveOffers({
  enabled = true,
  params = {},
}: Props = defaultProps) {
  const [authenticated] = useCurrentUser();

  return useInfiniteQuery({
    ...activeOffersInfiniteQueryOptions(params),
    enabled: authenticated && enabled,
  });
}
