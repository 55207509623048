import { BuyerOfferDisplayStatus } from '../types';

export function getOfferStatuses(offerDisplayStatus: BuyerOfferDisplayStatus) {
  const isAccepted = offerDisplayStatus === BuyerOfferDisplayStatus.ACCEPTED;
  const isCountered =
    offerDisplayStatus === BuyerOfferDisplayStatus.COUNTER_RECEIVED;
  const isReceived =
    offerDisplayStatus === BuyerOfferDisplayStatus.RECEIVED_PRIVATE_OFFER;

  return {
    isReceived,
    isRejected: offerDisplayStatus === BuyerOfferDisplayStatus.REJECTED,
    isSold:
      offerDisplayStatus === BuyerOfferDisplayStatus.SOLD_RECEIVED ||
      offerDisplayStatus === BuyerOfferDisplayStatus.SOLD_SENT ||
      offerDisplayStatus === BuyerOfferDisplayStatus.REDEEMED ||
      offerDisplayStatus === BuyerOfferDisplayStatus.SOLD_PRIVATE_OFFER,
    isPurchaseable: isAccepted || isCountered || isReceived,
  };
}
