import { Button } from '@depop/web-ui-kit/Button';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useTranslations } from 'next-intl';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import { OfferModalProductSummary } from '../../OfferModalProductSummary';
import { OfferHistory } from '../OfferHistory';

import styles from './styles.module.css';

import { OfferModalInput } from '@/components/OfferModalInput';
import { COUNTER_OFFER_TRANSLATION_KEYS } from '@/constants/offers';
import { useDebounce } from '@/modules/debounce/useDebounce';
import { DEBOUNCE_PRICE_QUOTE_DELAY } from '@/modules/offers/constants';
import { useCounterOfferMutation } from '@/modules/offers/hooks/useCounterOfferMutation';
import { useCounterOfferPriceQuoteMutation } from '@/modules/offers/hooks/useCounterOfferPriceQuoteMutation';
import { useOffersTracking } from '@/modules/offers/hooks/useOffersTracking';
import {
  CounterOfferProductPrice,
  OfferError,
  OfferRole,
} from '@/modules/offers/types';

type Props = {
  currencySymbol: string;
  productId: number;
  offerId: string;
  productPrice: CounterOfferProductPrice;
  userRole: OfferRole;
  onSuccessfulCounterOffer: () => void;
  isOpen: boolean;
  imageHref?: string;
  variantId?: number;
};

export function CounterOfferModalForm({
  currencySymbol,
  productId,
  offerId,
  productPrice,
  userRole,
  onSuccessfulCounterOffer,
  isOpen,
  imageHref,
  variantId,
}: Props) {
  const [offerPrice, setOfferPrice] = useState('');
  const [totalOfferPriceWithFees, setTotalOfferPriceWithFees] = useState('');
  const [error, setError] = useState('');
  const [counterOfferFeeQuoteError, setCounterOfferFeeQuoteError] =
    useState<OfferError>();
  const commonT = useTranslations('common');
  const offersT = useTranslations('offers');
  const { sendCounterOfferFormSubmitEvent } = useOffersTracking();
  const debouncedOfferPrice = useDebounce(
    offerPrice,
    DEBOUNCE_PRICE_QUOTE_DELAY
  );
  const showUpfrontFees =
    productPrice.currentPriceFees && Number(productPrice.currentPriceFees) > 0;

  function handleCounterOfferMutationError(mutationError: OfferError) {
    if (!mutationError?.id) {
      setError(offersT('Error.GENERIC_ERROR'));
    }

    if (mutationError.id) {
      if (mutationError.id === 'OFFER_BELOW_FLOOR_PRICE') {
        return setError(
          offersT(`Error.${mutationError.id}`, {
            floorPercentage: mutationError.action,
          })
        );
      }

      const key =
        COUNTER_OFFER_TRANSLATION_KEYS[mutationError.id] ||
        'Error.GENERIC_ERROR';

      setError(offersT(key));
    }
  }

  const counterOfferMutation = useCounterOfferMutation({
    offerId,
    onSuccess: onSuccessfulCounterOffer,
    onError: handleCounterOfferMutationError,
  });

  const counterOfferQuoteMutation = useCounterOfferPriceQuoteMutation({
    offerId,
    onSuccess: (data) => {
      if (data.price_break_down.marketplace_fee) {
        return setTotalOfferPriceWithFees(data.total_estimated_price_amount);
      }

      setTotalOfferPriceWithFees('');
    },
    onError: (mutationError: OfferError) => {
      setTotalOfferPriceWithFees('');
      setCounterOfferFeeQuoteError(mutationError);
      handleCounterOfferMutationError(mutationError);
    },
  });

  function handleOfferPriceChange(e: ChangeEvent<HTMLInputElement>) {
    setOfferPrice(e.target.value);
    setError('');
    setTotalOfferPriceWithFees('');
    setCounterOfferFeeQuoteError(undefined);
  }

  useEffect(() => {
    if (debouncedOfferPrice && showUpfrontFees) {
      counterOfferQuoteMutation.mutate({
        product_id: productId,
        offer_currency: productPrice.currencyName,
        offer_value: offerPrice,
        ...(variantId ? { variant_id: variantId } : {}),
      });
    }
  }, [debouncedOfferPrice]);

  useEffect(() => {
    if (!isOpen) {
      setError('');
      setOfferPrice('');
      setTotalOfferPriceWithFees('');
      setCounterOfferFeeQuoteError(undefined);
    }
  }, [isOpen]);

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    sendCounterOfferFormSubmitEvent({
      productId,
      offerId,
      productPrice,
      role: userRole,
      offerPrice,
    });

    counterOfferMutation.mutate({
      product_id: productId,
      offer_value: offerPrice,
      offer_currency: productPrice.currencyName,
      ...(variantId ? { variant_id: variantId } : {}),
    });
  }

  const shippingCostComponent =
    userRole === OfferRole.Buyer ? (
      <>
        {`+ ${currencySymbol}${productPrice.nationalShippingCost} `}
        <span className={styles.counterModalOfferFormShippingText}>
          {commonT('Shipping')}
        </span>
      </>
    ) : (
      <span className={styles.counterModalOfferFormShippingText}>
        {offersT('ShippingInfo')}
      </span>
    );

  const nationalShippingCost =
    Boolean(productPrice.nationalShippingCost) &&
    !counterOfferQuoteMutation.isPending
      ? shippingCostComponent
      : null;

  const showTotalOfferPriceWithFees =
    showUpfrontFees &&
    totalOfferPriceWithFees &&
    offerPrice &&
    !error &&
    !counterOfferQuoteMutation.isPending;

  const isSubmitDisabled =
    !offerPrice ||
    counterOfferQuoteMutation.isPending ||
    Boolean(counterOfferFeeQuoteError?.id);

  return (
    <form onSubmit={handleSubmit}>
      <OfferModalProductSummary
        currencySymbol={currencySymbol}
        productPrice={productPrice}
        imageHref={imageHref}
      />
      <OfferHistory
        currencySymbol={currencySymbol}
        userRole={userRole}
        currentPrice={productPrice.baseCurrentPrice}
        offerersHighestOffer={productPrice.offerersHighestOffer}
        sellersLowestOffer={productPrice.sellersLowestOffer}
      />
      <OfferModalInput
        currencySymbol={currencySymbol}
        labelText={offersT('CounterOfferModal.NewCounterAmountLabel')}
        offerPrice={offerPrice}
        errorText={error}
        placeholder={productPrice.baseCurrentPrice}
        onChange={handleOfferPriceChange}
        shippingCost={nationalShippingCost}
        totalOfferPriceWithFeeQuote={
          showTotalOfferPriceWithFees ? totalOfferPriceWithFees : undefined
        }
      />
      <Button
        className={styles.counterOfferModalFormSubmitButton}
        disabled={isSubmitDisabled}
        type="submit"
      >
        {offersT('SendOffer')}
      </Button>
      {userRole === OfferRole.Buyer && (
        <Text className={styles.counterOfferModalFormTipText} type="caption1">
          {offersT('CounterOfferModal.TipText')}
        </Text>
      )}
    </form>
  );
}
