import { BuyerOfferDisplayStatus } from '../types';

export function getOfferTileTitleKey(offerDisplayStatus: string) {
  switch (offerDisplayStatus) {
    case BuyerOfferDisplayStatus.SENT:
      return 'OfferSent';
    case BuyerOfferDisplayStatus.ACCEPTED:
      return 'ItsADeal';
    case BuyerOfferDisplayStatus.COUNTER_RECEIVED:
      return 'CounterOfferFromSeller';
    case BuyerOfferDisplayStatus.RECEIVED_PRIVATE_OFFER:
      return 'OfferReceived';
    case BuyerOfferDisplayStatus.COUNTER_SENT:
      return 'OfferStatus.CounterSent';
    case BuyerOfferDisplayStatus.EXPIRED_SENT:
    case BuyerOfferDisplayStatus.EXPIRED_PRIVATE_OFFER:
    case BuyerOfferDisplayStatus.EXPIRED_RECEIVED:
      return 'OfferStatus.Expired';
    case BuyerOfferDisplayStatus.REDEEMED:
    case BuyerOfferDisplayStatus.SOLD_PRIVATE_OFFER:
    case BuyerOfferDisplayStatus.SOLD_RECEIVED:
    case BuyerOfferDisplayStatus.SOLD_SENT:
      return 'OfferStatus.Sold';
    case BuyerOfferDisplayStatus.REJECTED:
      return 'OfferStatus.Declined';
    case BuyerOfferDisplayStatus.DISMISSED:
      return 'OfferStatus.SpecialOfferDismissed';
    default:
      return 'OfferStatus.Unknown';
  }
}
