import { BuyerOfferDisplayStatus, Offer } from '@/modules/offers/types';
import { getLogger } from '@/modules/observability/logging';

export function detectInvalidOfferStatuses(offers: Offer[]) {
  const validStatuses = Object.values(BuyerOfferDisplayStatus);
  const uniqueStatuses = new Set<BuyerOfferDisplayStatus>();
  offers.forEach((offer) => {
    uniqueStatuses.add(offer.offer_display_status);
  });
  uniqueStatuses.forEach((status) => {
    if (!validStatuses.includes(status)) {
      getLogger().warn(`Unknown offer status received >> ${status}`);
    }
  });
}
