import { useMutation } from '@tanstack/react-query';

import { createCounterOffer } from '../api';
import { CounterOfferRequestBody, OfferError } from '../types';

import { AxiosCompatibleError } from '@/modules/http/types';

type Props = {
  offerId: string;
  onSuccess?: () => void;
  onError?: (error: OfferError) => void;
};

export function useCounterOfferMutation({
  offerId,
  onSuccess,
  onError,
}: Props) {
  return useMutation({
    mutationFn: (body: CounterOfferRequestBody) =>
      createCounterOffer(offerId, body),
    onSuccess,
    onError: async (error: AxiosCompatibleError<OfferError>) => {
      const errorData = error?.response?.data;

      if (onError) {
        onError(errorData);
      }
    },
  });
}
