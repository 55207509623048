import { useQuery } from '@tanstack/react-query';

import { PRODUCT_BY_ID } from '@/modules/ReactQuery/cacheKeys.ts';
import { getProductById } from '@/modules/product/api.ts';
import { useSessionId } from '@/modules/storage/useSessionId.ts';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';

export function useProductById(productId?: string, includeOffers?: boolean) {
  const sessionId = useSessionId();
  const { web_upfront_fees } = useExperiments(['web_upfront_fees']);

  const [, user] = useCurrentUser();
  const { id: userId } = user || { id: 'UNAUTHENTICATED' };

  return useQuery({
    queryKey: [PRODUCT_BY_ID, productId, userId],
    queryFn: () =>
      getProductById({
        sessionId,
        productId: productId as string,
        include_offers: includeOffers,
        force_fee_calculation: web_upfront_fees === VARIANT_IDENTIFIER,
      }),
    enabled: Boolean(productId),
  });
}
