'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

import { OfferProductPrice } from '@/modules/offers/types';

type Props = {
  currencySymbol: string;
  productPrice: OfferProductPrice;
  imageHref?: string;
  sizeName?: string;
};

export function OfferModalProductSummary({
  currencySymbol,
  imageHref,
  productPrice,
  sizeName,
}: Props) {
  const tOffers = useTranslations('offers');
  const tCommon = useTranslations('common');

  const showUpfrontFees =
    productPrice.currentPriceFees && Number(productPrice.currentPriceFees) > 0;

  return (
    <div className={styles.wrapper}>
      <img
        className={styles.image}
        src={imageHref}
        height={88}
        width={88}
        alt=""
      />
      <div>
        {sizeName && <Text className={styles.sizeName}>{sizeName}</Text>}
        <Text bold>
          {tOffers('CurrentItemPrice', {
            price: currencySymbol + productPrice.baseCurrentPrice,
          })}
        </Text>
        {showUpfrontFees && (
          <Text className={styles.includingFeesText}>
            {tCommon('IncludingFees', {
              price: currencySymbol + productPrice.totalCurrentPrice,
            })}
          </Text>
        )}
      </div>
    </div>
  );
}
