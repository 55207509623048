import React from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

import { getOfferPriceSuggestions } from '@/modules/offers/helpers/getOfferPriceSuggestions';

type Props = {
  baseCurrentPrice: number;
  currencySymbol: string;
  selectedSuggestedPrice?: number;
  onSuggestedPriceClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    price: string,
    index: number
  ) => void;
};

export function SuggestedOfferPriceButtons({
  baseCurrentPrice,
  currencySymbol,
  selectedSuggestedPrice,
  onSuggestedPriceClick,
}: Props) {
  const t = useTranslations('offers');
  const offerPriceSuggestions = getOfferPriceSuggestions(baseCurrentPrice);

  return (
    <div className={styles.wrapper}>
      {offerPriceSuggestions.map((priceSuggestion, index) => {
        const isRecommended = index === 1;
        const isSelected = selectedSuggestedPrice === index;
        return (
          <div
            className={styles.buttonWrapper}
            key={priceSuggestion.percentageDiscount}
          >
            <button
              className={clsx({
                [styles.button]: true,
                [styles.buttonSelected]: isSelected,
                [styles.buttonRecommended]: isRecommended,
                [styles.buttonSelectedRecommended]: isSelected && isRecommended,
              })}
              onClick={(e) =>
                onSuggestedPriceClick(e, priceSuggestion.price, index)
              }
              type="button"
            >
              <Text type="caption2" as="span">
                {t('SuggestedOfferPrice.PercentageDiscount', {
                  percentage: priceSuggestion.percentageDiscount,
                })}
              </Text>
              <Text bold as="span">
                {currencySymbol}
                {priceSuggestion.price}
              </Text>
            </button>
            {isRecommended && (
              <Text className={styles.recommendationText} type="caption2">
                ({t('SuggestedOfferPrice.Recommendation')})
              </Text>
            )}
          </div>
        );
      })}
    </div>
  );
}
