'use client';

import React, { ChangeEvent, ReactNode } from 'react';
import { useTranslations } from 'next-intl';
import { Input } from '@depop/web-ui-kit/Input';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

type Props = {
  currencySymbol: string;
  errorText?: string;
  isLoading?: boolean;
  labelText: string;
  offerPrice: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  shippingCost?: ReactNode;
  totalOfferPriceWithFeeQuote?: string;
};

export function OfferModalInput({
  currencySymbol,
  errorText,
  isLoading,
  labelText,
  offerPrice,
  onChange,
  placeholder,
  shippingCost,
  totalOfferPriceWithFeeQuote,
}: Props) {
  const tCommon = useTranslations('common');

  return (
    <>
      <div className={styles.inputWrapper}>
        <Input
          type="number"
          id="offerModalInput"
          labelText={labelText}
          leadingText={currencySymbol}
          value={offerPrice}
          isClearable={false}
          onChange={onChange}
          errorText={errorText}
          placeholder={placeholder}
          isLoading={isLoading}
        />
        {shippingCost && !isLoading && (
          <Text className={styles.shippingCostText} type="caption2">
            {shippingCost}
          </Text>
        )}
      </div>
      {totalOfferPriceWithFeeQuote && (
        <Text
          className={styles.totalOfferPriceWithFeeQuote}
          data-testid="offerModalInput__includingFees"
        >
          {tCommon('IncludingFees', {
            price: `${currencySymbol} ${totalOfferPriceWithFeeQuote}`,
          })}
        </Text>
      )}
    </>
  );
}
