import { Button } from '@depop/web-ui-kit/Button';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useTranslations } from 'next-intl';
import React from 'react';

import styles from './styles.module.css';

const DEPOP_CLOUDFRONT_HOST = process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST;

type Props = { username: string; onExitClick: () => void };

export function CounterOfferModalSuccess({ username, onExitClick }: Props) {
  const t = useTranslations('offers');

  return (
    <div className={styles.counterOfferModalSuccessContainer}>
      <img
        src={`${DEPOP_CLOUDFRONT_HOST}/web/assets/make-offer-spin-sticker.gif`}
        height={188}
        width={188}
        alt=""
      />
      <Text className={styles.counterOfferModalSuccessText}>
        {t.rich('CounterOfferModal.CounterOfferSent.Buyer', {
          bold: () => (
            <Text bold as="span">
              {username}
            </Text>
          ),
        })}
      </Text>
      <Button
        className={styles.counterOfferModalSuccessButton}
        onClick={onExitClick}
      >
        {t('HelpExitButton')}
      </Button>
    </div>
  );
}
