import { ProductById, Seller_snake } from '../types';

import { CheckoutProduct } from '@/modules/checkout/types';

export function transformProductToCheckout(
  product: Omit<ProductById, 'seller'> & { seller: Seller_snake }
): CheckoutProduct {
  const checkoutProduct: CheckoutProduct = {
    id: product.id,
    slug: product.slug,
    countryCode: product.country_code,
    categoryId: product.category_id,
    price: {
      priceAmount: product.pricing.original_price.total_price,
      nationalShippingCost: product.pricing.national_shipping_cost?.total_price,
      internationalShippingCost:
        product.pricing.international_shipping_cost?.total_price,
      discountedPriceAmount: product.pricing.discounted_price?.total_price,
      currencyName: product.pricing.currency_name,
    },
    seller: {
      id: product.seller.id,
      username: product.seller.username,
      pictureUrl: product.seller.picture?.[0].url,
      initials: product.seller.initials,
      verified: product.seller.verified,
      firstName: product.seller.first_name,
      lastName: product.seller.last_name,
      picture: product.seller.picture,
      reviewsTotal: product.seller.reviews_total,
      reviewsRating: product.seller.reviews_rating,
      itemsSold: product.seller.items_sold,
      lastSeen: product.seller.last_seen,
    },
    pictures: product.pictures,
    description: product.description,
    brandName: product.brand_name,
    productType: product.product_type || '',
  };

  if (product.variants) {
    checkoutProduct.size = {
      id: Object.keys(product.variants)[0],
    };
  }

  return checkoutProduct;
}
