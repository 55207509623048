import React, { useRef } from 'react';
import { Button } from '@depop/web-ui-kit/Button';
import { useTranslations } from 'next-intl';
import { MoreIcon } from '@depop/web-ui-kit/Icons/MoreIcon';

import styles from './styles.module.css';

import { ActionMenu } from '@/components/ActionMenu';
import { TextMenuItemClick } from '@/components/ActionMenu/ActionMenuItems';
import { useDismissOfferMutation } from '@/modules/offers/hooks/useDismissOfferMutation';
import { Offer } from '@/modules/offers/types';

type Props = Pick<Offer, 'offer_id'> & {
  refetchParentQuery: () => void;
};

export function OfferTileMenu({ offer_id, refetchParentQuery }: Props) {
  const elementRef = useRef<HTMLDivElement>(null);
  const t = useTranslations('offers');
  const { mutate } = useDismissOfferMutation({
    offer_id,
    onSuccess: refetchParentQuery,
  });

  const link: Array<TextMenuItemClick> = [
    {
      key: 'dismiss',
      onClick: mutate,
      translation: t('DismissButton'),
    },
  ];

  return (
    <div className={styles.offerTileMenuWrapper} ref={elementRef}>
      <ActionMenu
        actionMenuId="offerMenu"
        elementRef={elementRef}
        menuItems={link}
        trigger={({ toggleActionMenu }: { toggleActionMenu: () => void }) => (
          <Button
            className={styles.offerTileMenuButton}
            aria-label={t('OfferMenu')}
            onClick={toggleActionMenu}
            outline
            size="sm"
          >
            <MoreIcon size={16} />
          </Button>
        )}
      />
    </div>
  );
}
