import { Button } from '@depop/web-ui-kit/Button';
import { useTranslations } from 'next-intl';
import React from 'react';

export type Props = {
  canMakeCounterOffer: boolean;
  canMakeNewOffer: boolean;
  isPurchaseable: boolean;
  handleMakeCounterOfferClick: () => void;
  handleMakeNewOfferClick: () => void;
  handlePurchaseClick: () => void;
};

export function OfferTileButtons({
  canMakeCounterOffer,
  canMakeNewOffer,
  isPurchaseable,
  handleMakeCounterOfferClick,
  handleMakeNewOfferClick,
  handlePurchaseClick,
}: Props) {
  const t = useTranslations('offers');

  if (canMakeNewOffer) {
    return (
      <Button block outline onClick={handleMakeNewOfferClick}>
        {t('MakeNewOffer')}
      </Button>
    );
  }

  return (
    <>
      {isPurchaseable && (
        <Button block onClick={handlePurchaseClick}>
          {t('Buy')}
        </Button>
      )}
      {canMakeCounterOffer && (
        <Button block outline onClick={handleMakeCounterOfferClick}>
          {t('CounterButton')}
        </Button>
      )}
    </>
  );
}
