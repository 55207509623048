import { LazyLoadImage } from '@depop/web-ui-kit/LazyLoadImage';
import { H3 } from '@depop/web-ui-kit/Typography/H3';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useTranslations } from 'next-intl';
import React, { useEffect, useState } from 'react';

import { CounterOfferModal } from '../CounterOfferModal';
import { ExtendedLink } from '../ExtendedLink';
import { MakeOfferModal } from '../MakeOfferModal';

import { OfferTileButtons } from './OfferTileButton';
import { OfferTileMenu } from './OfferTileMenu';
import styles from './styles.module.css';

import { addCheckoutProducts } from '@/modules/checkout/helpers';
import { CheckoutType } from '@/modules/checkout/types';
import { getCurrencySymbolFromCurrencyCode } from '@/modules/currencies/helpers';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { getOfferPriceTextKey } from '@/modules/offers/helpers/getOfferPriceTextKey';
import { getOfferStatuses } from '@/modules/offers/helpers/getOfferStatuses';
import { getOfferTileTitleKey } from '@/modules/offers/helpers/getOfferTileTitle';
import { useOffersTracking } from '@/modules/offers/hooks/useOffersTracking';
import { Offer, OfferRole } from '@/modules/offers/types';
import { transformProductToCheckout } from '@/modules/product/helpers/transformProductToCheckout';
import { useProductById } from '@/modules/product/hooks/useProductById';
import { useExtendedRouter } from '@/modules/routing/useExtendedRouter';

type Props = Offer & {
  refetchParentQuery: () => void;
  includeBreakpointStyles?: boolean;
  showInlineButtons?: boolean;
  showImageMarginTop?: boolean;
};

export function OfferTile({
  offer_display_status,
  expires_at,
  price_currency,
  offer_price,
  offer_id,
  prices,
  product_id,
  product_slug,
  picture_data,
  variant_id,
  can_make_counter_offer,
  can_make_new_offer,
  national_shipping_cost_for_buyer,
  offerers_highest_offer_value,
  sellers_lowest_offer_value,
  refetchParentQuery,
  seller_username,
  includeBreakpointStyles = true,
  showInlineButtons = false,
}: Props) {
  const [isMakeOfferModalOpen, setIsMakeOfferModalOpen] = useState(false);
  const [isCounterOfferModalOpen, setIsCounterOfferModalOpen] = useState(false);
  const [hasOfferSentSuccessfully, setHasOfferSentSuccessfully] =
    useState(false);
  const commonT = useTranslations('common');
  const offersT = useTranslations('offers');
  const { web_upfront_fees } = useExperiments(['web_upfront_fees']);
  const router = useExtendedRouter();
  const { sendOfferBuyNowEvent } = useOffersTracking();
  const { data: product, isError } = useProductById(String(product_id));
  const titleKey = getOfferTileTitleKey(offer_display_status);
  const { isReceived, isRejected, isSold, isPurchaseable } =
    getOfferStatuses(offer_display_status);
  const priceTextKey = getOfferPriceTextKey(offer_display_status);
  const currencySymbol = getCurrencySymbolFromCurrencyCode(price_currency);
  const isUpfrontFeesVariant = web_upfront_fees === VARIANT_IDENTIFIER;
  const showOfferButtons =
    isPurchaseable || can_make_counter_offer || can_make_new_offer;
  const totalCurrentPrice = isUpfrontFeesVariant
    ? prices.current_price.total_estimated_price_amount
    : prices.current_price.price;
  const currentPriceFees = isUpfrontFeesVariant
    ? prices.current_price.price_break_down.marketplace_fee
    : undefined;

  async function handleBuyClick() {
    if (!product || !product?.data.seller) {
      return;
    }

    sendOfferBuyNowEvent({
      productId: product_id,
      currency: price_currency,
      originalPrice: prices.original_price.price,
      currentPrice: prices.current_price.price,
      offerPrice: offer_price.price,
    });

    addCheckoutProducts({
      products: [
        transformProductToCheckout({
          ...product.data,
          seller: product.data.seller,
        }),
      ],
      type: CheckoutType.SingleProduct,
    });

    router.push('/pay');
  }

  useEffect(() => {
    if (hasOfferSentSuccessfully) {
      refetchParentQuery();
    }
  }, [isMakeOfferModalOpen, isCounterOfferModalOpen]);

  return (
    <div
      className={clsx(styles.offerTileContainer, {
        [styles.offerTileBreakpointStyles]: includeBreakpointStyles,
      })}
    >
      {isReceived && (
        <OfferTileMenu
          offer_id={offer_id}
          refetchParentQuery={refetchParentQuery}
        />
      )}
      <div className={styles.offerTileRow}>
        <div className={styles.offerTileTextContainer}>
          <div>
            <H3
              className={clsx(styles.offerTileTitle, {
                [styles.purchaseableOfferTitle]: isPurchaseable,
                [styles.rejectedOfferTitle]: isRejected,
              })}
            >
              {offersT(titleKey)}
            </H3>
            {!isSold && (
              <Text
                className={clsx(styles.offerTileText, {
                  [styles.purchaseableOfferTileText]: isPurchaseable,
                })}
              >
                {`${
                  isPurchaseable ? offersT('BuyBefore') : offersT('Expires')
                } ${format(new Date(expires_at), `d MMMM 'at' haaa`)}`}
              </Text>
            )}
          </div>
          {!isSold && (
            <div>
              <div className={styles.offerTilePriceContainer}>
                <H3 className={styles.offerTilePrice}>
                  {offersT(priceTextKey)}: {currencySymbol}
                  {offer_price.price}
                </H3>
                <Text
                  className={styles.offerTileOriginalPrice}
                  data-testid="strikethrough"
                >
                  {currencySymbol}
                  {prices.original_price.price}
                </Text>
              </div>
              {Number(offer_price.price_break_down.marketplace_fee) > 0 &&
                isUpfrontFeesVariant && (
                  <Text className={styles.offerTileText}>
                    {commonT('IncludingFees', {
                      price: `${currencySymbol}${offer_price.total_estimated_price_amount}`,
                    })}
                  </Text>
                )}
            </div>
          )}
        </div>
        <ExtendedLink
          className={styles.offerTileLinkContainer}
          href={`/products/${product_slug}/`}
        >
          <LazyLoadImage
            alt=""
            src={picture_data?.formats.P5.url}
            height={320}
            width={320}
          />
        </ExtendedLink>
        {showOfferButtons && (
          <div
            className={clsx(styles.offerTileButtonsContainer, {
              [styles.offerTileButtonsContainerInline]: showInlineButtons,
            })}
          >
            <OfferTileButtons
              canMakeNewOffer={can_make_new_offer}
              canMakeCounterOffer={can_make_counter_offer}
              isPurchaseable={isPurchaseable}
              handleMakeNewOfferClick={() => setIsMakeOfferModalOpen(true)}
              handleMakeCounterOfferClick={() =>
                setIsCounterOfferModalOpen(true)
              }
              handlePurchaseClick={handleBuyClick}
            />
          </div>
        )}
      </div>
      {can_make_new_offer && (
        <MakeOfferModal
          isOpen={isMakeOfferModalOpen}
          setOpen={setIsMakeOfferModalOpen}
          productId={product_id}
          productPrice={{
            currencyName: price_currency,
            baseOriginalPrice: prices.original_price.price,
            baseCurrentPrice: prices.current_price.price,
            totalCurrentPrice,
            nationalShippingCost: national_shipping_cost_for_buyer,
            currentPriceFees,
          }}
          productImageHref={picture_data?.formats.P2.url}
          selectedVariantId={variant_id}
          showOfferSentContent={hasOfferSentSuccessfully}
          onOfferSent={() => setHasOfferSentSuccessfully(true)}
        />
      )}
      {can_make_counter_offer && (
        <CounterOfferModal
          isOpen={isCounterOfferModalOpen}
          setOpen={setIsCounterOfferModalOpen}
          currencySymbol={currencySymbol}
          productPrice={{
            currencyName: price_currency,
            baseOriginalPrice: prices.original_price.price,
            baseCurrentPrice: prices.current_price.price,
            totalCurrentPrice,
            nationalShippingCost: national_shipping_cost_for_buyer,
            offerersHighestOffer: offerers_highest_offer_value,
            sellersLowestOffer: sellers_lowest_offer_value,
            currentPriceFees,
          }}
          imageHref={picture_data?.formats.P2.url}
          userRole={OfferRole.Buyer}
          productId={product_id}
          offerId={offer_id}
          variantId={variant_id}
          onOfferSent={() => setHasOfferSentSuccessfully(true)}
          offererUsername={seller_username}
        />
      )}
      {isError && (
        <Text className={styles.offerTileText}>{offersT('Error.Generic')}</Text>
      )}
    </div>
  );
}
