import { OfferStatus } from '@/modules/product/types';

export const INACTIVE_OFFER_STATES = [
  OfferStatus.UNAVAILABLE,
  OfferStatus.EXPIRED,
  OfferStatus.REJECTED,
  OfferStatus.RESCINDED,
];

export const COUNTER_OFFER_TRANSLATION_KEYS: Record<string, string> = {
  PRODUCT_UNAVAILABLE: 'Error.Counter.PRODUCT_UNAVAILABLE',
  USER_ERROR: 'Error.USER_ERROR',
  OFFER_LOWER_THAN_PREVIOUS: 'Error.OFFER_LOWER_THAN_PREVIOUS',
  OFFER_HIGHER_THAN_CURRENT_OFFER:
    'Error.Counter.OFFER_HIGHER_THAN_CURRENT_OFFER',
  OFFER_BELOW_FLOOR_PRICE: 'Error.OFFER_BELOW_FLOOR_PRICE',
  EXISTING_OFFER_SENT: 'Error.EXISTING_OFFER_SENT',
};
