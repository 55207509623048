import {
  infiniteQueryOptions,
  QueryFunctionContext,
} from '@tanstack/react-query';

import { fetchBuyerActiveOffers, fetchBuyerInactiveOffers } from './api';
import { detectInvalidOfferStatuses } from './helpers/detectInvalidOfferStatuses';

import {
  RQ_BUYER_ACTIVE_OFFERS,
  RQ_BUYER_INACTIVE_OFFERS,
} from '@/modules/ReactQuery/cacheKeys';
import { ActiveOffersParams } from '@/modules/offers/types';

export function activeOffersInfiniteQueryOptions(
  params: Omit<ActiveOffersParams, 'offset_id'>
) {
  return infiniteQueryOptions({
    queryKey: [RQ_BUYER_ACTIVE_OFFERS],
    queryFn: async (ctx: QueryFunctionContext) => {
      const res = await fetchBuyerActiveOffers({
        ...params,
        offset_id: ctx.pageParam as string | undefined,
      });
      detectInvalidOfferStatuses(res.data.objects);
      return res?.data;
    },
    getNextPageParam: (lastPage) => lastPage?.meta?.last_offset_id,
    initialPageParam: undefined,
  });
}

export function inactiveOffersInfiniteQueryOptions(
  params: Omit<ActiveOffersParams, 'offset_id'>
) {
  return infiniteQueryOptions({
    queryKey: [RQ_BUYER_INACTIVE_OFFERS],
    queryFn: async (ctx: QueryFunctionContext) => {
      const res = await fetchBuyerInactiveOffers({
        ...params,
        offset_id: ctx.pageParam as string | undefined,
      });
      detectInvalidOfferStatuses(res.data.objects);
      return res?.data;
    },
    getNextPageParam: (lastPage) => lastPage?.meta?.last_offset_id,
    initialPageParam: undefined,
  });
}
