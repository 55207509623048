'use client';

import React, { useEffect } from 'react';
import { useTranslations } from 'next-intl';
import { Modal } from '@depop/web-ui-kit/Modal';
import { H2 } from '@depop/web-ui-kit/Typography/H2';

import styles from './styles.module.css';

import { MakeOfferModalSent } from '@/components/MakeOfferModal/MakeOfferModalSent';
import { MakeOfferModalForm } from '@/components/MakeOfferModal/MakeOfferModalForm';
import { useOffersTracking } from '@/modules/offers/hooks/useOffersTracking';
import { OfferProductPrice } from '@/modules/offers/types';
import { getCurrencySymbolFromCurrencyCode } from '@/modules/currencies/helpers';

type Props = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  productId: number;
  onOfferSent: () => void;
  showOfferSentContent?: boolean;
  productImageHref?: string;
  productPrice: OfferProductPrice;
  selectedVariantId?: number;
  sizeName?: string;
  predefinedOfferPrice?: string;
};

export function MakeOfferModal({
  isOpen,
  setOpen,
  productId,
  showOfferSentContent,
  onOfferSent,
  productImageHref,
  productPrice,
  selectedVariantId,
  sizeName,
  predefinedOfferPrice,
}: Props) {
  const t = useTranslations('offers');
  const { sendOffersSentViewEvent, sendMakeOfferViewEvent } =
    useOffersTracking();

  const title = showOfferSentContent ? t('OfferSent') : t('MakeOffer');

  const currencySymbol = getCurrencySymbolFromCurrencyCode(
    productPrice.currencyName
  );

  function handleOfferSentSuccess() {
    sendOffersSentViewEvent(productId);
    onOfferSent();
  }

  useEffect(() => {
    if (isOpen) {
      sendMakeOfferViewEvent(productId);
    }
  }, [isOpen, productId]);

  return (
    <Modal
      isOpen={isOpen}
      setOpen={setOpen}
      customOverrides={{
        header: (
          <div className={styles.header}>
            <H2>{title}</H2>
          </div>
        ),
      }}
      content={() => (
        <div className={styles.container}>
          {showOfferSentContent ? (
            <MakeOfferModalSent />
          ) : (
            <MakeOfferModalForm
              predefinedOfferPrice={predefinedOfferPrice}
              currencySymbol={currencySymbol}
              productId={productId}
              productImageHref={productImageHref}
              productPrice={productPrice}
              sizeName={sizeName}
              onOfferSent={handleOfferSentSuccess}
              selectedVariantId={selectedVariantId}
            />
          )}
        </div>
      )}
    />
  );
}
