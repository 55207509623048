import React from 'react';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { Button } from '@depop/web-ui-kit/Button';

import styles from './styles.module.css';

import { ExtendedLink } from '@/components/ExtendedLink';

const DEPOP_CLOUDFRONT_HOST = process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST;

export function MakeOfferModalSent() {
  const t = useTranslations('offers');
  const pathname = usePathname();
  const isOffersPage = pathname === '/messages/offers';

  return (
    <div className={styles.content}>
      <img
        src={`${DEPOP_CLOUDFRONT_HOST}/web/assets/make-offer-spin-sticker.gif`}
        height={188}
        width={188}
        alt=""
      />
      <Text className={styles.successText}>{t('OfferSuccess')}</Text>
      {!isOffersPage && (
        <ExtendedLink href="/messages/offers/" passHref>
          <Button as="a" block className={styles.messagesCta}>
            {t('SeeOffers')}
          </Button>
        </ExtendedLink>
      )}
    </div>
  );
}
