import { Button } from '@depop/web-ui-kit/Button';
import { TimerCountdownIcon } from '@depop/web-ui-kit/Icons/TimerCountdownIcon';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useMutation } from '@tanstack/react-query';
import { useTranslations } from 'next-intl';
import React, { ChangeEvent, useEffect, useState } from 'react';

import styles from './styles.module.css';

import { SuggestedOfferPriceButtons } from '@/components/MakeOfferModal/MakeOfferModalForm/SuggestedOfferPriceButtons';
import { OfferModalInput } from '@/components/OfferModalInput';
import { OfferModalProductSummary } from '@/components/OfferModalProductSummary';
import { useDebounce } from '@/modules/debounce/useDebounce';
import { AxiosCompatibleError } from '@/modules/http/types';
import { createOffer, createOfferPriceQuote } from '@/modules/offers/api';
import {
  DEBOUNCE_PRICE_QUOTE_DELAY,
  GENERIC_ERROR_ID,
} from '@/modules/offers/constants';
import { useOffersTracking } from '@/modules/offers/hooks/useOffersTracking';
import {
  CreateOfferRequestBody,
  OfferError,
  OfferPriceQuoteRequestBody,
  OfferProductPrice,
} from '@/modules/offers/types';

type Props = {
  currencySymbol: string;
  onOfferSent: () => void;
  productId: number;
  productImageHref?: string;
  productPrice: OfferProductPrice;
  selectedVariantId?: number;
  sizeName?: string;
  predefinedOfferPrice?: string;
};

const EMPTY_ERROR = {
  code: -1, // an error code which doesn't exist
  id: '',
  message: '',
  action: '',
};

export function MakeOfferModalForm({
  currencySymbol,
  onOfferSent,
  productId,
  productPrice,
  productImageHref,
  selectedVariantId,
  sizeName,
  predefinedOfferPrice,
}: Props) {
  const tOffers = useTranslations('offers');
  const tCommon = useTranslations('common');
  const { sendSuggestedOffersActionEvent, sendMakeOfferFormSubmitEvents } =
    useOffersTracking();
  const [selectedSuggestedPrice, setSelectedSuggestedPrice] = useState<
    number | undefined
  >(undefined);
  const [totalOfferPriceWithFeeQuote, setTotalOfferPriceWithFeeQuote] =
    useState('');
  const [error, setError] = useState<OfferError>(EMPTY_ERROR);
  const [inputDirty, setInputDirty] = useState(false);
  const [offerPrice, setOfferPrice] = useState(predefinedOfferPrice || '');
  const debouncedOfferPrice = useDebounce(
    offerPrice,
    DEBOUNCE_PRICE_QUOTE_DELAY
  );

  const showUpfrontFees =
    productPrice.currentPriceFees && Number(productPrice.currentPriceFees) > 0;

  function handleSelectedPriceClick(
    e: React.MouseEvent<HTMLButtonElement>,
    price: string,
    index: number
  ) {
    e.preventDefault();

    sendSuggestedOffersActionEvent(productId, price, productPrice.currencyName);

    setError(EMPTY_ERROR);
    setOfferPrice(price);
    setSelectedSuggestedPrice(index);
  }

  function handleOfferPriceChange(e: ChangeEvent<HTMLInputElement>) {
    setInputDirty(true);
    setError(EMPTY_ERROR);
    setSelectedSuggestedPrice(undefined);
    setOfferPrice(e.target.value);
  }

  async function handleOfferRequestError(
    errorRes: AxiosCompatibleError<OfferError>
  ) {
    const error = errorRes.response?.data;

    if (!error?.id) {
      return setError({
        action: '',
        code: 0,
        id: GENERIC_ERROR_ID,
        message: '',
      });
    }
    setError(error);
  }

  function getErrorText(error: OfferError) {
    if (!error.id) {
      return undefined;
    }
    if (error.id === 'OFFER_BELOW_FLOOR_PRICE') {
      return tOffers(`Error.${error.id}`, {
        floorPercentage: error.action,
      });
    }
    return tOffers(`Error.${error.id}`) || tOffers(`Error.${GENERIC_ERROR_ID}`);
  }

  const errorText = getErrorText(error);

  const createOfferMutation = useMutation({
    mutationFn: (body: CreateOfferRequestBody) => createOffer(body),
    onSuccess: onOfferSent,
    onError: (error: AxiosCompatibleError<OfferError>) =>
      handleOfferRequestError(error),
  });

  const priceQuoteMutation = useMutation({
    mutationFn: (body: OfferPriceQuoteRequestBody) =>
      createOfferPriceQuote(body),
    onSuccess: (res) => {
      const { data } = res;

      if (data.price_break_down.marketplace_fee) {
        setTotalOfferPriceWithFeeQuote(data.total_estimated_price_amount);
      } else {
        setTotalOfferPriceWithFeeQuote('');
      }
    },
    onError: (error: AxiosCompatibleError<OfferError>) => {
      setTotalOfferPriceWithFeeQuote('');
      handleOfferRequestError(error);
    },
  });

  const showNationalShippingCost = Boolean(
    productPrice.nationalShippingCost && !priceQuoteMutation.isPending
  );

  const hasClearedInput = !offerPrice && inputDirty;

  const isTotalOfferPriceWithFeeQuoteVisible =
    showUpfrontFees &&
    totalOfferPriceWithFeeQuote &&
    !errorText &&
    !hasClearedInput &&
    !priceQuoteMutation.isPending;

  const isRequestPending =
    createOfferMutation.isPending || priceQuoteMutation.isPending;

  const isSubmitDisabled =
    !Boolean(offerPrice) ||
    isRequestPending ||
    // We still want to enable the button if there is a generic API request error
    // e.g. the Mobile API is down, which is why we're checking for an error id
    Boolean(error?.id);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    sendMakeOfferFormSubmitEvents(productId, offerPrice, productPrice);

    createOfferMutation.mutate({
      product_id: productId,
      offer_value: offerPrice,
      offer_currency: productPrice.currencyName,
      ...(selectedVariantId ? { variant_id: selectedVariantId } : {}),
    });
  }

  useEffect(() => {
    if (debouncedOfferPrice && showUpfrontFees) {
      priceQuoteMutation.mutate({
        product_id: productId,
        offer_value: debouncedOfferPrice,
        offer_currency: productPrice.currencyName,
        variant_id: selectedVariantId,
      });
    }
  }, [
    debouncedOfferPrice,
    productId,
    productPrice.currencyName,
    selectedVariantId,
    showUpfrontFees,
  ]);

  return (
    <form onSubmit={handleSubmit}>
      <OfferModalProductSummary
        imageHref={productImageHref}
        currencySymbol={currencySymbol}
        productPrice={productPrice}
        sizeName={sizeName}
      />
      <OfferModalInput
        currencySymbol={currencySymbol}
        errorText={errorText}
        isLoading={priceQuoteMutation.isPending}
        labelText={tOffers('OfferAmountLabel')}
        offerPrice={offerPrice}
        onChange={handleOfferPriceChange}
        placeholder={productPrice.baseCurrentPrice}
        shippingCost={
          showNationalShippingCost ? (
            <>
              {`+ ${currencySymbol}${productPrice.nationalShippingCost} `}
              <span className={styles.shippingText}>{tCommon('Shipping')}</span>
            </>
          ) : undefined
        }
        totalOfferPriceWithFeeQuote={
          isTotalOfferPriceWithFeeQuoteVisible
            ? totalOfferPriceWithFeeQuote
            : undefined
        }
      />
      <SuggestedOfferPriceButtons
        baseCurrentPrice={parseFloat(productPrice.baseCurrentPrice)}
        selectedSuggestedPrice={selectedSuggestedPrice}
        onSuggestedPriceClick={handleSelectedPriceClick}
        currencySymbol={currencySymbol}
      />
      <Button
        block
        className={styles.submitButton}
        disabled={isSubmitDisabled}
        isLoading={createOfferMutation.isPending}
        isSuccess={createOfferMutation.isSuccess}
        type="submit"
      >
        {tOffers('SendOffer')}
      </Button>
      <div className={styles.timeWrapper}>
        <TimerCountdownIcon size={18} />
        <Text type="caption2">{tOffers('OfferTimelimitWarning')}</Text>
      </div>
    </form>
  );
}
