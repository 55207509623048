import { OfferStatus } from '../product/types';

export const PURCHASABLE_OFFER_STATES = [
  OfferStatus.ACCEPTED,
  OfferStatus.SELLER_COUNTERED,
  OfferStatus.SELLER_INITIATED,
];

export const DEBOUNCE_PRICE_QUOTE_DELAY = 1000;

export const GENERIC_ERROR_ID = 'GENERIC_ERROR';

export const buyerStatuses = new Set([
  'ACCEPTED',
  'SENT',
  'COUNTER_SENT',
  'EXPIRED_SENT',
  'REJECTED',
  'SOLD_SENT',
  'SENT_PRIVATE_OFFER',
  'BINDING_SENT',
  'BINDING_COUNTER_SENT',
]);

export const sellerStatuses = new Set([
  'COUNTER_RECEIVED',
  'EXPIRED_RECEIVED',
  'SOLD_RECEIVED',
  'RECEIVED_PRIVATE_OFFER',
  'EXPIRED_PRIVATE_OFFER',
  'SOLD_PRIVATE_OFFER',
  'DISMISSED',
]);
